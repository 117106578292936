<template>
    <div data-comp="Icon">
        <component :is="icons[props.icon]"/>
    </div>
</template>

<script lang="ts" setup>
    import { PropType } from 'vue';
    import { icons } from '@/SVG/.icons'; /** imported as module so that it's only initialized once */

    export type tIcon = keyof typeof icons;

    const props = defineProps({
        icon: {
            type: String as PropType<keyof typeof icons>,
            required: true,
        },
    });
</script>

<style lang="scss" scoped>
    @layer props {
        [data-comp=Icon] {
            --size: 100%;  // syntax: <length>
        }
    }

    @layer reset {
        [data-comp=Icon] {
            svg {
                width: var(--size);
                height: var(--size);
            }
        }
    }
</style>
